@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.TaskIntro {
	position: relative;
	width: 100%;		
	.TaskIntro-text {
		border-radius: 1.1em;
		color: $text-dark;
		p {
			margin-top: 0;
			font-size: 1.17em;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.TaskIntro-files {
			margin: 1em 0;
		}
		.TaskIntro-requiredAnswers {
			display: inline-block;
			margin-top: 1em;
			font-weight: 900;
			font-size: 1.17em;
		}
	}
	

	.TaskIntro-image {
		position: relative;
		width: 100%;
		margin-top: 0em;
		background-position: center center;
	}

	&.design-color-circle {
		.TaskIntro-image {
			height: 18em;
			background-image: url('../../../assets/images/tasks/multiple-choice/design-color-circle.jpeg');
		}
	}

	&.build-floor-plan {
		.TaskIntro-image {
			height: 28.2em;
			background-image: url('../../../assets/images/tasks/multiple-choice/build-floor-plan.png');
		}
	}
	&.production-average-temp {
		.TaskIntro-image {
			height: 20em;
			background-image: url('../../../assets/images/tasks/multiple-choice/production-average-temp.png');
		}
	}

	&.production-technician {
		.TaskIntro-image {
			height: 20em;
			background-image: url('../../../assets/images/tasks/multiple-choice/production-technician.jpg');
		}
	}

	&.production-technicians-lab {
		.TaskIntro-image {
			height: 15em;
			background-image: url('../../../assets/images/tasks/multiple-choice/production-technicians-lab.jpg');
		}
	}

	&.production-technician-robot-coworker {
		.TaskIntro-image {
			height: 18em;
			background-image: url('../../../assets/images/tasks/multiple-choice/production-technician-robot-coworker.jpg');
		}
	}

	&.production-technicians-robot-graph {
		.TaskIntro-image {
			height: 22em;
			background-image: url('../../../assets/images/tasks/multiple-choice/production-technicians-robot-graph.png');
		}
	}

	&.production-technicians-multiple-graphs {
		.TaskIntro-image {
			height: 20em;
			background-image: url('../../../assets/images/tasks/multiple-choice/production-technicians-multiple-graphs.png');
		}
	}

	&.it-squares {
		.TaskIntro-image {
			height: 22.11em;
			background-image: url('../../../assets/images/tasks/input-answer/it-squares.svg');
		}
	}

	&.it-url {
		@include flex('center', 'flex-start');
		.TaskIntro-text {
			width: 50%;
		}
		.TaskIntro-image {
			width: 50%;
			height: 12.5em;
			background-size: 100% auto;
			background-position: top center;
			background-image: url('../../../assets/images/tasks/multiple-choice/it-url.png');
		}
	}

	&.design-product-1,
	&.design-product-2,
	&.design-product-3,
	&.design-product-3-solved {
		@include flex('center', 'flex-start');
		.TaskIntro-text {
			width: 50%;
		}
		.TaskIntro-image {
			width: 50%;
			height: 26em;
			background-image: url('../../../assets/images/tasks/multiple-choice/design-product-1.svg');
		}
	}
	&.design-product-2 {
		.TaskIntro-image {
			background-image: url('../../../assets/images/tasks/multiple-choice/design-product-2.svg');
		}
	}
	&.design-product-3 {
		.TaskIntro-image {
			background-image: url('../../../assets/images/tasks/multiple-choice/design-product-3.svg');
		}
	}
	&.design-product-3-solved {
		.TaskIntro-image {
			background-image: url('../../../assets/images/tasks/multiple-choice/design-product-3-solved.svg');
		}
	}

	&.research-lab-people {
		.TaskIntro-image {
			height: 20em;
			background-image: url('../../../assets/images/tasks/multiple-choice/research-lab-people.jpg');
		}	
	}

	&.research-periodic-table {
		.TaskIntro-image {
			height: 22em;
			background-image: url('../../../assets/images/tasks/multiple-choice/research-periodic-table.jpeg');
		}
	}

	/* Task intro, image and text side by side */
	&.electrical-fire,
	&.liquid-fire,
	&.furniture-fire,
	&.particle-extinguisher,
	&.co2-extinguisher,
	&.waterhose,
	&.fireblanket {
		position: relative;
		width: 100%;
		@include flex('center');
		flex-direction: row-reverse;
				
		.TaskIntro-text {
			border-radius: 1.1em;
			min-width: calc(100% - (100%/3));
			color: $text-dark;
			p {
				margin-top: 2em;
				font-size: 1.17em;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			.TaskIntro-files {
				margin: 1em 0;
			}
			.TaskIntro-requiredAnswers {
				display: inline-block;
				margin-top: 1em;
				font-weight: 900;
				font-size: 1.17em;
			}
		}
		.TaskIntro-image {
			position: relative;
			width: calc(100%/3);
			margin-top: 0em;
			background-position: center center;
		}
	}
	&.electrical-fire {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/electrical-fire.svg');
		}
	}
	&.liquid-fire {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/liquid-fire.svg');
		}
	}
	&.furniture-fire {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/furniture-fire.svg');
		}
	}
	&.particle-extinguisher {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/particle.svg');
		}
	}
	&.co2-extinguisher {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/co2.svg');
		}
	}
	&.waterhose {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/waterhose.svg');
		}
	}
	&.fireblanket {
		.TaskIntro-image {
			height: 10em;
			background-image: url('../../../assets/images/tasks/multiple-choice/fire/blanket.svg');
		}
	}
}