@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.MultipleChoice {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();
		
	.MultipleChoice-intro {
		position: relative;
		width: 100%;
	}

	.MultipleChoice-options {
		position: relative;
		margin-top: 1em;
		
		&:not(.multiline) {
			@include flex('flex-start', 'center', 'column');
		}
		&.multiline {
			@include flex();
			flex-wrap: wrap;
			width: 100%;
		}
		.MultipleChoice-option {
			position: relative;
			width: 25em;
			min-height: 3em;
			line-height: 1.25em;
			border-radius: 0.55em;
			font-weight: bold;
			text-align: center;
			padding: 0.75em 1.25em 0.5em 1.25em;
			margin: 0 auto 0.8em auto;
			
			cursor: pointer;
			@include flex('center', 'center');
			@include no-select();
			@include button-blue();
			p {
				text-transform: none;
				margin: 0;
			}

			&.game2 {
				@include button-purple();
				p {
					text-transform: none;
					margin: 0;
				}
			}
			&.animateCorrect {@include animateCorrect();
			}
			&.animateWrong {@include animateWrong();}
			&.disabled {cursor: not-allowed;}
		}
		
	}

	&:not(.images) {
		.MultipleChoice-options {
			.MultipleChoice-option {
				
				&.correct,
				&.animateCorrect {
					@include button-green();
					span {
						text-transform: none;
					}
					&:hover,
					&:focus,
					&:active {
						color: $white;
						background-color: $green;
						border-bottom: 0.44em solid $greenDark;
					}
				}
				&.wrong,
				&.animateWrong {
					@include button-red();
					span {
						text-transform: none;
					}
					&:hover,
					&:focus,
					&:active {
						color: $white;
						background-color: $red;
						border-bottom: 0.44em solid $redDark;
					}
				}
				&.completed {
					cursor: not-allowed;
					&:hover,
					&:focus,
					&:active {
						color: $white;
						background-color: $blue;
						border-bottom: 0.44em solid $bluegradient;

						&.game2 {
							background-color: $purpleLight;
							border-bottom: 0.44em solid $purpleDark;
						}
					}
				}
				&.selected {
					cursor: default;
				}
			}
		}
	}

	&.images {
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-option { 
				min-height: auto;
				background: none;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				border: none;
				margin: 0;
				padding: 0;
				border-radius: 0;
				@include box-shadow(0, 0, 0, 0, transparent);
				span {
					display: none;
					&:nth-child(2) {
						position: absolute;
						bottom: 0em;
						right: 0em;
						width: 1.5em;
						height: 1.5em;
					}
				}
				&.selected,
				&.correct,
				&.animateCorrect,
				&.wrong,
				&.animateWrong {
					border: none;	
					@include box-shadow(0, 0, 0, 0, transparent);
					span:nth-child(2) {display: block;}
				}
				&.correct,
				&.animateCorrect {
					span:nth-child(2) {
						background-image: url('../../../assets/images/icons/icon-correct.svg');
						@include animateCorrect();
					}
				}
				&.wrong,
				&.animateWrong {
					span:nth-child(2) {
						background-image: url('../../../assets/images/icons/icon-wrong.svg');
						@include animateWrong();
					}
				}		
			}
		}

		&.build-warning {
			.MultipleChoice-options {
				@include flex('center','','row');
				margin-top: 2em;
				padding-left: 4em;
				padding-right: 4em;
				.MultipleChoice-option { 
					width: 10em;
					height: 10em;
					border-radius: 4em;
					margin: 1em;
					&.option-flammable {
						background-image: url(../../../assets/images/tasks/multiple-choice/warning-labels/flammable.png);
						background-size: contain;
					}
					&.option-explosive {
						background-image: url(../../../assets/images/tasks/multiple-choice/warning-labels/explosive.jpeg);
						background-size: contain;
					}
					&.option-irritant {
						background-image: url(../../../assets/images/tasks/multiple-choice/warning-labels/irritant.jpeg);
						background-size: contain;
					}
					&.option-acute-toxicity {
						background-image: url(../../../assets/images/tasks/multiple-choice/warning-labels/acute-toxicity.jpeg);
						background-size: contain;
					}
					&.option-gases {
						background-image: url(../../../assets/images/tasks/multiple-choice/warning-labels/gases.png);
						background-size: contain;
					}
					&.option-corrosive {
						background-image: url(../../../assets/images/tasks/multiple-choice/warning-labels/corrosive.jpeg);
						background-size: contain;
					}
				}
			}
		}

		&.design-designguide {
			.MultipleChoice-options {
				margin-top: 2em;
				.MultipleChoice-option { 
					width: 4em;
					height: 4em;
					border-radius: 4em;
					margin-right: 2em;
					&.option-concern {background-color: #0E2C21;}
					&.option-production {background-color: #82c17a;}
					&.option-build {background-color: #2c441f;}
					&.option-design {background-color: #296238;}
					&.option-research {background-color: #327394;}
					&.option-it {background-color: #82c17a;}
					&.option-secondary-cyan {background-color: #009FDF;}
					&.option-secondary-lime {background-color: #ABBC38;}
					&.option-contrast-yellow {background-color: #E7CD14;}
					&.option-contrast-orange {background-color: #ED8B00;}
					&.option-contrast-red {background-color: #851302;}
					&.option-contrast-purple {background-color: #512D6D};
					&.selected {cursor: default;}
					&.completed {cursor: default;}
				}
			}
		}

		&.it-fonts {
			.MultipleChoice-options {
				margin-top: 2em;
				.MultipleChoice-option {
					height: 2.5em;
					margin-top: 3.66em;
					background-size: contain;
					span {
						display: none;
						&:nth-child(2) {
							bottom: -0.75em;
							right: -0.75em;
						}
					}
					&.option-arial {
						background-image: url(../../../assets/images/tasks/multiple-choice/it-fonts/arial.svg);
					}
					&.option-roboto {
						background-image: url(../../../assets/images/tasks/multiple-choice/it-fonts/roboto.svg);
					}
					&.option-calibri {
						background-image: url(../../../assets/images/tasks/multiple-choice/it-fonts/calibri.svg);
					}
					&.option-times {
						background-image: url(../../../assets/images/tasks/multiple-choice/it-fonts/times.svg);
					}
				}
			}
		}
		/* Fire */
		&.extinguish-pc-fire, &.extinguish-liquid-fire, &.extinguish-furniture-fire {
			.MultipleChoice-options {
				margin-top: 2em;

				.MultipleChoice-option {
					height: 14em;
					width: calc(100%/4);
					margin-top: 3.66em;
					background-size: contain;
				
					&.option-particle {
						background-image: url(../../../assets/images/tasks/multiple-choice/fire/particle.svg);
					}
					&.option-water {
						background-image: url(../../../assets/images/tasks/multiple-choice/fire/waterhose.svg);
					}
					&.option-co2 {
						background-image: url(../../../assets/images/tasks/multiple-choice/fire/co2.svg);
					}
					&.option-blanket {
						background-image: url(../../../assets/images/tasks/multiple-choice/fire/blanket.svg);
					}
				}
			}
		}
	}
}